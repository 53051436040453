.MuiDrawer-paper {
  /* background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */
  /* background-image: linear-gradient(to top, #dfe9f3 0%, white 100%); */
  background-color: #2F4874 !important;
  color: #fff !important;
  width: 195px;

}

.drawer {
  width: 200px;
  margin-left: 1rem;
}

.logoShow {
  margin-top: 1rem;
  margin-left: 2.5rem;
  height: 50px;
  width: 100px;
}

.sidebar-icon{
  color:#fff !important;
}

.sidebar-item:hover {
  background-color: #2F4874;
}

.sidebar-icon:hover {
  color: #424242 !important;
} 


.active {
background-color:#5177b8  !important;
color:#fff !important;

}

.footer {
  position: absolute;
  bottom: 0;
}

.footer-label{
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer !important;
  position: absolute;
  bottom: 0;
  margin: 15px 20px !important;
}

.footer-label:hover{
  color: #424242 !important;
}