.custom-calendar {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
}


.customButton{
  background-color: #5177b8;
  color: #fff;
  border: none;
  padding: 3px;
  margin: 5px;
  cursor: pointer;
  font-weight: 500;
}

.customButton.active {
  border-bottom: 3px solid white;
}

.custom-label{
  color: #fff;
  font-weight: bolder;
  margin:0px 10px;
}

.custom-arrow{
  color: #fff;
  cursor: pointer;
}

.custom-arrow:hover{
  color: #ccc;
}

.rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    background-color: #5177b8;;
    border: none;
    padding: 0.4rem 1.5rem;
    margin: 0.5rem;
    border-radius: 4px;
    color: #fff;
    height: 35px;
  }

.rbc-btn-group button:not(:first-child):not(:last-child){
    border-radius: 4px;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    background-color: #e6e6e6;
    border-color: #858585;
    color:#424242
  }
  .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    color: #373a3c;
    background-color: #d4d4d4;
    border-color: #858585;
  }
  .rbc-toolbar button:focus {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #858585;
  }
  .rbc-toolbar button:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #858585;
  }

.rbc-toolbar-label{
    font-weight: 600;
    font-size: 20px;
}

.rbc-today{
    background-color:#c2d6fa;
}

.rbc-show-more {
  color: #858585;
  font-weight: 500;
}

.rbc-show-more:hover{
  color:#5177b8;
}


/* Day Column Page  */

.rbc-time-content {
  display: none;
}

.rbc-time-header-gutter{
display: none;
}

.rbc-time-view{
  border:none;
}

.rbc-time-header-content{
  border-left: none;
}


.rbc-event-continues-after {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.rbc-event-continues-prior {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rbc-time-view  .rbc-row{
  margin:1rem 0;
}

.rbc-time-view .rbc-event-content{
  padding: 10px;
}

.rbc-time-view .rbc-today{
  background-color: #fff;
}

/* Responsive for screens */

@media (max-width: 600px) {
  .rbc-toolbar button {
    padding: 5px;
    margin: 5px;
  }
}

@media (max-width: 988px) {
  .rbc-btn-group {
    display: flex;
    flex-direction: column;
  }

  .rbc-toolbar-label {
    flex: 1 0 auto;
    text-align: center;
    padding: 10px 0;
  }
}

@media (max-width: 490px) {
  .rbc-toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rbc-btn-group {
    display: flex;
    flex-direction: column;
  }

  .rbc-toolbar-label {
    flex: 1 0 auto;
    text-align: center;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }
}
