*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  min-height: 100vh;
  font-family:'Open Sans',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: 'Open Sans',sans-serif;
}

a {
  color: #5177B8;
}

.PrimaryButton{
  background-color:#5177B8 !important;
  color: #fff !important;
  }

.SecondaryButton {
  color: #5177B8 !important;
}

.container{
  display: flex;
}


.modalBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow:  3px 3px 2px 1px  #63676a;
  padding:3rem ;
  word-break: break-all;
}

.loginImage {
  background-size: cover; 
}

.logoLogin{
  width:130px;
  margin-bottom: 2rem;
}

.MuiTabs-indicator{
  background-color: #5177B8  !important;
}

.appbar{
  color:#fff !important;
background: radial-gradient(circle, hsla(218, 42%, 52%, 1) 0%, hsla(218, 42%, 32%, 1) 100%) !important;

}

/* //! To hide the custom input ranges in react DefinedRange */

.rdrInputRanges{
  display: none;
}

.FilterButton{
  color: #fff !important;
  border: none;
  background-color: #5177B8 !important;
  padding: 1rem  !important;
  height: 35px !important;
}

@media (max-width: 600px) {
  .MuiGrid-item {
    width: 100% !important;
  }
}


.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
  background-color: rgba(0,0,0,0.2) !important;
}

#donut_single path {
  stroke-opacity: 1 !important;
}

.table-header{
  background-color:#5177B8 !important;
  height: 50px;
}

.table-header-small{
  background-color:#5177B8 !important;
  height: 40px;
}


.container-header{
  color: #2F4874 !important;
  font-weight: 600 !important;
}

.container-icon{
  color: #5177B8 !important;
}

.DashboardTable{
 background: linear-gradient(90deg, rgba(47,72,116,1) 10%, rgba(81,119,184,1) 31%, rgba(47,72,116,1) 52%, rgba(81,119,184,1) 68%, rgba(47,72,116,1) 89%) !important;
  /* background: linear-gradient(90deg, rgba(47,72,116,1)  0%,  rgba(81,119,184,1)  100%) !important; */

}


.react-joyride__tooltip div {
  text-align: left !important;
}

.react-joyride__tooltip div h4{
  margin-left: 10px !important;
}

/* to change the color of a label in stepper in Course Creation Modal*/
.css-t3cpi5-MuiStepIcon-text  {
  fill: #fff !important;
}
