
body {
margin: 0;
padding: 0;
background-color: #f6f6f6;

}

.App {
display: flex;
}

